export const windowIsExist = typeof window !== "undefined";

export const scrollBehaviorTo = (y = 0) => {
  const supportsNativeSmoothScroll = "scrollBehavior" in document.documentElement.style;

  if (supportsNativeSmoothScroll) {
    window.scrollTo({
      top: y,
      behavior: "smooth",
    });
  } else {
    window.scrollTo(0, y);
  }
};

export const scrollY = () => {
  //TODO: window.pageYOffset === window.scrollY; always true

  const supportPageOffset = window.scrollX !== undefined;
  const isCSS1Compat = (document.compatMode || "") === "CSS1Compat";
  return supportPageOffset
    ? window.scrollY
    : isCSS1Compat
    ? document.documentElement.scrollTop
    : document.body.scrollTop;
};

export const buildObjectWithIndex = (objs) => {
  let a = {};
  Object.keys(objs).forEach((key, index) => {
    a[key] = objs[key];
    a[index] = objs[key];
  });
  return a;
};

export const isIEBrowser = () => {
  const ua = window.navigator.userAgent;
  const isIE = /MSIE|Trident/.test(ua);
  return isIE;
};

export const isHTML = (str) => {
  if (typeof document !== "undefined") {
    let a = document.createElement("div");
    a.innerHTML = str;

    for (let c = a.childNodes, i = c.length; i--; ) {
      if (c[i].nodeType === 1) return true;
    }

    return false;
  }
};

export const isInAnIframe = () => {
  if (windowIsExist) {
    return window.location !== window.parent.location;
  }

  return false;
};

export const getDefaultUrl = () => (windowIsExist ? window.location.href : "");

export const getUrlParams = (url = getDefaultUrl()) => {
  let params = {};
  if (windowIsExist) {
    url.replace(/[?&]+([^=&]+)=([^&]*)/gi, (_, key, value) => {
      params[key] = value;
    });
  }

  return params;
};

export const getParamInUrl = (key, url = getDefaultUrl()) => getUrlParams(url)[key];

export const arrayToQueryParams = (paramsArray) => {
  let queryParams = "";

  paramsArray.map((item) => {
    if (queryParams === "") {
      queryParams = "?";
    } else {
      queryParams += "&";
    }
    queryParams += `${Object.keys(item)}=${item[Object.keys(item)]}`;
    return queryParams;
  });

  return queryParams;
};

export const isObjectEmpty = (obj) => {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const delay = (ms = 1000) => {
  return new Promise((resolve) => setTimeout(() => resolve(), ms));
};

export const toCammelCase = (str) => str.replace(/-./g, (x) => x[1].toUpperCase());